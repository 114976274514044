import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pipe,
  Pump,
  Pump2,
  Pond,
  ValveConnect,
  PipePlaceholder,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowSmall,
  PumpsRowCustom,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Info,
  MultiInfo,
  Statistic,
  PLCStateRouter,
  PLCColorRouter,
  // MobileStatTable,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AlarmReset,
  VFDPumpControl,
  DrainValveControl,
  MultiSetpointControl,
  InfoButton,
  MultiInfoButton,
} from 'isno/lib/components/controls';

import PLC from 'isno/lib/static/images/PLC.png';
import valveImg from 'isno/lib/static/images/Valve/Valve.png';
// import { EquipmentPropType } from 'isno/lib/components/prop-types/Equipment';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Valves
  loonPeakChargeValve: 'loon_peak_charge_valve_open_close',
  realEstateChargeValve: 'real_estate_charge_valve_open_close',
  southPeakChargeValve: 'south_peak_charge_valve_open_close',
  loonPeakMainValve: 'loon_peak_main_valve_open_close',
  southPeakMainValve: 'south_peak_main_valve_open_close',
  position: 'position',
  cv1Position: 'cv1_position',
  cv2Position: 'cv2_position',
  cv3Position: 'cv3_position',
  cv4Position: 'cv4_position',
  cv5Position: 'cv5_position',
  dischargeWaterPressure: 'discharge_water_pressure',

  // Pumps
  amps: 'current',
  hours: 'run_time',
};

const sum = (a, b) => {
  const sumValue = a + b;
  return sumValue;
};

function NewRiverScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        // props.fetchLocation(16), // (3)
        props.fetchLocation(15), // (7)
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) {
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    // props.fetchLocation(16); // (3)
    props.fetchLocation(15); // (7)
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null;
    };
  }, []);

  // const locationEquipment3 = props.locations?.locations?.[16]?.equipment; // (3)
  const locationEquipment7 = props.locations?.locations?.[15]?.equipment; // (7)
  if (!locationEquipment7) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer flex="3" border backgroundColor={theme.backgroundColor} label="New River">
            <InfoControlRow>
              {/* <MultiInfo
                title="Total New River Flow"
                subtitles={['Electronic', 'Mechanical']}
                statistics={[
                  locationEquipment7.Other2.ioValues.total_electronic_water_flow?.value,
                  locationEquipment7.Other2.ioValues.total_mechanical_water_flow?.value,
                ]}
                labels={[
                  locationEquipment7.Other2.ioValues.total_electronic_water_flow?.ioValueType?.units,
                  locationEquipment7.Other2.ioValues.total_mechanical_water_flow?.ioValueType?.units,
                ]}
                // alertText={(() => {
                //   if (locationEquipment3?.Other?.ioValues?.pemi_river_low_flow?.value) {
                //     return 'Low Flow';
                //   } else if (locationEquipment3?.Other?.ioValues?.pemi_river_low_flow_warning?.value) {
                //     return 'Low Flow Warning';
                //   } else {
                //     return null;
                //   }
                // })()}
                // alertTop="35px"
                // alertLeft={locationEquipment3?.Other?.ioValues?.pemi_river_low_flow_warning?.value ? '-12px' : '23px'}
              /> */}
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[26], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[26], theme)}
                // alertTop="20px"
                // alertLeft="-3px"
                // alertText={locationEquipment7?.Other?.ioValues?.plc_low_battery?.value ? 'Low Battery' : null}
              />
              <Info
                title="Instrument Air"
                statistic={locationEquipment7?.IA?.ioValues?.low_air_pressure?.value === 0 ? 'Enabled' : 'Low Pressure'}
                color={locationEquipment7?.IA?.ioValues?.low_air_pressure?.value === 0 ? theme.onGreen : theme.alarm}
                label=""
                alertTop="20px"
                alertLeft="-3px"
                alertText={locationEquipment7?.IA?.ioValues?.instrument_air_alarm?.value ? 'Instrument Air Alarm' : null}
              />
              <MultiInfoButton
                title="Loon Peak Electronic Flow"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment7?.['LP Electronic Flow Totalizer']?.ioValues?.trip_volume?.value,
                  locationEquipment7?.['LP Electronic Flow Totalizer']?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment7?.['LP Electronic Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment7?.['LP Electronic Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment7?.['LP Electronic Flow Totalizer']?.ioValues?.trip_volume_reset?.id,
                  locationEquipment7?.['LP Electronic Flow Totalizer']?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <MultiInfoButton
                title="Loon Peak Mechanical Flow"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment7?.['LP Mechanical Flow Totalizer']?.ioValues?.trip_volume?.value,
                  locationEquipment7?.['LP Mechanical Flow Totalizer']?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment7?.['LP Mechanical Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment7?.['LP Mechanical Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment7?.['LP Mechanical Flow Totalizer']?.ioValues?.trip_volume_reset?.id,
                  locationEquipment7?.['LP Mechanical Flow Totalizer']?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              {/* <MultiInfoButton
                title="Real Estate Electronic Flow"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment7?.['RE Electronic Flow Totalizer']?.ioValues?.trip_volume?.value,
                  locationEquipment7?.['RE Electronic Flow Totalizer']?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment7?.['RE Electronic Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment7?.['RE Electronic Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment7?.['RE Electronic Flow Totalizer']?.ioValues?.trip_volume_reset?.id,
                  locationEquipment7?.['RE Electronic Flow Totalizer']?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <MultiInfoButton
                title="Real Estate Mechanical Flow"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment7?.['RE Mechanical Flow Totalizer']?.ioValues?.trip_volume?.value,
                  locationEquipment7?.['RE Mechanical Flow Totalizer']?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment7?.['RE Mechanical Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment7?.['RE Mechanical Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment7?.['RE Mechanical Flow Totalizer']?.ioValues?.trip_volume_reset?.id,
                  locationEquipment7?.['RE Mechanical Flow Totalizer']?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              /> */}
              <MultiInfoButton
                title="South Peak Electronic Flow"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment7?.['SP Electronic Flow Totalizer']?.ioValues?.trip_volume?.value,
                  locationEquipment7?.['SP Electronic Flow Totalizer']?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment7?.['SP Electronic Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment7?.['SP Electronic Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment7?.['SP Electronic Flow Totalizer']?.ioValues?.trip_volume_reset?.id,
                  locationEquipment7?.['SP Electronic Flow Totalizer']?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <MultiInfoButton
                title="South Peak Mechanical Flow"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment7?.['SP Mechanical Flow Totalizer']?.ioValues?.trip_volume?.value,
                  locationEquipment7?.['SP Mechanical Flow Totalizer']?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment7?.['SP Mechanical Flow Totalizer']?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment7?.['SP Mechanical Flow Totalizer']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment7?.['SP Mechanical Flow Totalizer']?.ioValues?.trip_volume_reset?.id,
                  locationEquipment7?.['SP Mechanical Flow Totalizer']?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <MultiSetpointControl
                title="CV1&2 PID"
                pGainLabel="Back P-Gain SP"
                iGainLabel="Back I-Gain SP"
                pGainLabel2="Hill P-Gain SP"
                iGainLabel2="Hill I-Gain SP"
                settings
                pidData={locationEquipment7?.['CV1&2 PID']}
                IOValueIDs={[
                  `${locationEquipment7?.['CV1&2 PID']?.ioValues?.back_auto_setpoint?.id}`,
                  `${locationEquipment7?.['CV1&2 PID']?.ioValues?.hill_auto_setpoint?.id}`,
                  `${locationEquipment7?.['CV1&2 PID']?.ioValues?.manual_setpoint?.id}`,
                ]}
                statistics={[
                  `${locationEquipment7?.['CV1&2 PID']?.ioValues?.back_auto_setpoint.value}`,
                  `${locationEquipment7?.['CV1&2 PID']?.ioValues?.hill_auto_setpoint.value}`,
                  `${locationEquipment7?.['CV1&2 PID']?.ioValues?.manual_setpoint.value}`,
                ]}
                labels={['Back Auto SP:', 'Hill Auto SP:', 'Manual Setpoint:']}
                units={[
                  locationEquipment7?.['CV1&2 PID']?.ioValues?.back_auto_setpoint?.ioValueType?.units,
                  locationEquipment7?.['CV1&2 PID']?.ioValues?.hill_auto_setpoint?.ioValueType?.units,
                  locationEquipment7?.['CV1&2 PID']?.ioValues?.manual_setpoint?.ioValueType?.units,
                ]}
                precision={[1, 1, 1]}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  autoManual: 'auto_manual',
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  pressureMode: 'hill_back',
                  pGainSetpoint: 'back_pgain_setpoint',
                  iGainSetpoint: 'back_igain_setpoint',
                  pGainSetpoint2: 'hill_pgain_setpoint',
                  iGainSetpoint2: 'hill_igain_setpoint',
                }}
                iGainPrecision={3}
                pGainPrecision={3}
                readValues={[0, 1]}
                writeValues={[0, 1]}
                buttons
                inputNumWidth="72px"
              />
              <VFDPumpControl
                title="Real Estate Valve CV3 PID"
                settings
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="72px"
                pumpData={{ id: `${locationEquipment7?.['CV3 PID']?.id}` }}
                pidData={locationEquipment7?.['CV3 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                iGainPrecision={3}
                pGainPrecision={3}
                pGainMax="32767"
                iGainMax="32767"
                pGainMin="1"
                iGainMin="1"
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <VFDPumpControl
                title="Discharge Valve CV4 PID"
                settings
                autoPrecision={1}
                manPrecision={1}
                inputNumWidth="72px"
                pumpData={{ id: `${locationEquipment7?.['CV4 PID']?.id}` }}
                pidData={locationEquipment7?.['CV4 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                }}
                iGainPrecision={3}
                pGainPrecision={3}
                pGainMax="32767"
                iGainMax="32767"
                pGainMin="1"
                iGainMin="1"
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <DrainValveControl
                title="Loon Peak Drain Valve"
                valveData={locationEquipment7.LPDV}
                openIOValueID={locationEquipment7.LPDV?.ioValues?.open?.id}
                closeIOValueID={locationEquipment7.LPDV?.ioValues?.close?.id}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  closeOpen: 'open_closed',
                  autoManual: 'manual_auto',
                }}
                readValues={[1, 0, 0, 1]}
                writeValues={[1, 0, 0, 1]}
              />
              <DrainValveControl
                title="South Peak Drain Valve"
                valveData={locationEquipment7.SPDV}
                openIOValueID={locationEquipment7.SPDV?.ioValues?.open?.id}
                closeIOValueID={locationEquipment7.SPDV?.ioValues?.close?.id}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  closeOpen: 'open_closed',
                  autoManual: 'manual_auto',
                }}
                readValues={[1, 0, 0, 1]}
                writeValues={[1, 0, 0, 1]}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment7?.Other?.ioValues}
              />
              <AlarmReset
                title="System reset"
                buttonText="Reset"
                alarmData={locationEquipment7?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarm: 'master_alarm',
                  masterAlarmReset: 'alarm_reset',
                }}
                resetWriteValue={1}
              />
              <Info
                title="Room Temp."
                statistic={locationEquipment7?.Other?.ioValues?.room_temperature?.value}
                label={locationEquipment7?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
            </InfoControlRow>
            <PumpsRowSmall>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <div css={styles.flexCol}>
                  <div css={css`height: 0px; position: relative; top: 20px; left: 33px;`}>
                    <PipePlaceholder
                      horizontalAbove
                      minWidth="87px"
                    />
                  </div>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder
                      capBefore
                      minWidth="87px"
                    />
                  </div>
                  <div css={[css`width: 51px; display: flex; flex-direction: column; justify-content: center; align-items: center;`, styles.hiddenMobile]}>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        border
                        label={locationEquipment7?.Other?.ioValues.loon_peak_discharge_water_pressure?.ioValueType?.units}
                        statistic={locationEquipment7?.Other?.ioValues.loon_peak_discharge_water_pressure?.value}
                      />
                    </div>
                    <div css={css`width: 51px; height: 4px;`}>
                      <PipePlaceholder
                        minWidth="87px"
                      />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.loon_peak_electronic_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.loon_peak_electronic_water_flow?.value}
                        floatLabel="(Electronic)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`width: 51px; height: 4px;`}>
                      <PipePlaceholder
                        minWidth="87px"
                      />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.loon_peak_mechanical_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.loon_peak_mechanical_water_flow?.value}
                        floatLabel="(Mechanical)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                  </div>
                  <div css={css`width: 51px; height: 52px;`}>
                    <PipePlaceholder
                      minWidth="87px"
                      pipeBelow="right"
                      connectBelow
                    />
                  </div>
                  <div css={css`height: 0px; left: 33px; position: relative;`}>
                    <ValveConnect
                      manual
                      readOnly
                      horizontal="below"
                      minWidth="87px"
                      valveData={{ state: locationEquipment7?.Other?.ioValues[IOValueKeys.loonPeakChargeValve]?.value, name: '' }}
                      valveStateKeys={{
                        0: 'Open',
                        1: 'Closed',
                      }}
                      redImageKey="Closed"
                      greenImageKey="Open"
                    />
                  </div>
                </div>
                <div css={styles.flexCol}>
                  <div css={css`height: 0px; position: relative; top: 20px; left 33px;`}>
                    <PipePlaceholder
                      horizontalAbove
                      minWidth="87px"
                    />
                  </div>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder
                      capBefore
                      minWidth="87px"
                    />
                  </div>
                  <div css={[css`width: 51px; display: flex; justify-content: center;`, styles.hiddenMobile]}>
                    <Statistic
                      label={locationEquipment7?.Other?.ioValues.real_estate_discharge_water_pressure?.ioValueType?.units}
                      border
                      statistic={locationEquipment7?.Other?.ioValues.real_estate_discharge_water_pressure?.value}
                    />
                  </div>
                  <ValveConnect
                    manual
                    readOnly
                    minWidth="87px"
                    // pipeBelow="middle"
                    valveData={{ state: locationEquipment7?.Other?.ioValues[IOValueKeys.realEstateChargeValve]?.value, name: '' }}
                    valveStateKeys={{
                      0: 'Open',
                      1: 'Closed',
                    }}
                    redImageKey="Closed"
                    greenImageKey="Open"
                  />
                  <div css={css`height: 0px; position: relative;`}>
                    <ValveConnect
                      manual
                      readOnly
                      horizontal="below"
                      minWidth="147px"
                      valveData={{ state: locationEquipment7?.Other?.ioValues[IOValueKeys.southPeakChargeValve]?.value, name: '' }}
                      valveStateKeys={{
                        0: 'Open',
                        1: 'Closed',
                      }}
                      redImageKey="Closed"
                      greenImageKey="Open"
                    />
                  </div>
                </div>
                {/* <ValveConnect
                  manual
                  readOnly
                  horizontal="below"
                  minWidth="147px"
                  valveData={{ state: locationEquipment7?.Other?.ioValues[IOValueKeys.southPeakChargeValve]?.value, name: '' }}
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                />
                <div css={styles.flexCol}>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder
                      capBefore
                    />
                  </div>
                  <div css={[css`width: 51px; display: flex; flex-direction: column; justify-content: center; align-items: center;`, styles.hiddenMobile]}>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.south_peak_discharge_water_pressure?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.south_peak_discharge_water_pressure?.value}
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.south_peak_electronic_discharge_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.south_peak_electronic_discharge_water_flow?.value}
                        floatLabel="(Electronic)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.south_peak_mechanical_discharge_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.south_peak_mechanical_discharge_water_flow?.value}
                        floatLabel="(Mechanical)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                  </div>
                  <PipePlaceholder
                    minWidth="45px"
                    pipeBelow="left"
                    connectBelow
                  />
                </div> */}
              </div>
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <div css={styles.flexCol}>
                  <div css={css`height: 0px; position: relative; top: 20px; left 33px;`}>
                    <PipePlaceholder
                      horizontalAbove
                      minWidth="87px"
                    />
                  </div>
                  <div css={css`height: 25px;`}>
                    <PipePlaceholder
                      capBefore
                    />
                  </div>
                  <div css={[css`width: 51px; display: flex; flex-direction: column; justify-content: center; align-items: center;`, styles.hiddenMobile]}>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.south_peak_discharge_water_pressure?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.south_peak_discharge_water_pressure?.value}
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.south_peak_electronic_discharge_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.south_peak_electronic_discharge_water_flow?.value}
                        floatLabel="(Electronic)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                    <div css={css`height: 4px; width: 51px;`}>
                      <PipePlaceholder />
                    </div>
                    <div css={css`width: fit-content;`}>
                      <Statistic
                        label={locationEquipment7?.Other?.ioValues.south_peak_mechanical_discharge_water_flow?.ioValueType?.units}
                        border
                        statistic={locationEquipment7?.Other?.ioValues.south_peak_mechanical_discharge_water_flow?.value}
                        floatLabel="(Mechanical)"
                        floatLabelTop="2px"
                        floatLabelLeft="66px"
                      />
                    </div>
                  </div>
                  <PipePlaceholder
                    minWidth="45px"
                    pipeBelow="left"
                    connectBelow
                  />
                </div>
                <div css={css`position: relative; top: 20px;`}>
                  <ValveConnect
                    reverseValve
                    minWidth="0px"
                    nameLeft="-14px"
                    nameTop="-34px"
                    pipeAbove="middle"
                    pipeBelow="right"
                    valveData={locationEquipment7?.LPDV}
                    IOValueKeys={{
                      opened: 'open_closed',
                      closed: 'open_closed',
                    }}
                    readValues={[0, 1]}
                    valveStateKeys={{
                      0: 'Closed',
                      1: 'Open',
                    }}
                    redImageKey="Closed"
                    greenImageKey="Open"
                  />
                </div>
                <div css={css`position: relative; top: 20px;`}>
                  <ValveConnect
                    reverseValve
                    minWidth="0px"
                    nameLeft="-14px"
                    nameTop="-34px"
                  // capAfter
                  // capLabel="To Well"
                  // capLabelTop="10px"
                  // capLabelLeft="-65px"
                    pipeAbove="left"
                  // pipeBelow="middle"
                    valveData={locationEquipment7?.SPDV}
                    IOValueKeys={{
                      opened: 'open_closed',
                      closed: 'open_closed',
                    }}
                    readValues={[0, 1]}
                    valveStateKeys={{
                      0: 'Closed',
                      1: 'Open',
                    }}
                    redImageKey="Closed"
                    greenImageKey="Open"
                  />
                  <div css={css`position: absolute; height: 281px;`}>
                    <PipePlaceholder minWidth="0px" />
                  </div>
                </div>
              </div>
            </PumpsRowSmall>
            <PumpsRowCustom height="60px">
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <ValveConnect
                  manual
                  readOnly
                  minWidth="147px"
                  nameLeft="-90px"
                  valveData={{ state: locationEquipment7?.Other?.ioValues?.[IOValueKeys.loonPeakMainValve]?.value, name: 'Loon Peak' }}
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                />
                <ValveConnect
                  minWidth="87px"
                  reverseValve
                  percent
                  percentTop="-25px"
                  percentLeft="-25px"
                  // nameTop="-2px"
                  nameLeft="25px"
                  nameWidth="84px"
                  valveData={{
                    name: 'Real Estate',
                    id: locationEquipment7?.CV3?.id,
                    state: locationEquipment7?.CV3?.ioValues?.[IOValueKeys.position]?.value,
                  }}
                  binary={false}
                />
              </div>
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <ValveConnect
                  manual
                  readOnly
                // pipeBelow="left"
                  minWidth="226px"
                  nameLeft="50px"
                  nameWidth="86px"
                  valveData={{ state: locationEquipment7?.Other?.ioValues?.[IOValueKeys.southPeakMainValve]?.value, name: 'South Peak' }}
                  valveStateKeys={{
                    0: 'Open',
                    1: 'Closed',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                />
                <div css={css`margin-top: 5px;`}>
                  <PipePlaceholder pipeAbove="left" minWidth="0px" />
                </div>
              </div>
            </PumpsRowCustom>
            <PumpsRowCustom height="80px">
              <PipePlaceholder hidden />
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <ValveConnect
                  readOnly
                  minWidth="0px"
                  percent
                  percentTop="50px"
                  nameLeft="-70px"
                  pipeAbove="right"
                  reverseValve
                  valveData={{ state: locationEquipment7?.CV2?.ioValues?.[IOValueKeys.position]?.value, name: 'CV2B' }}
                  binary={false}
                />
                <ValveConnect
                  readOnly
                  minWidth="0px"
                  percent
                  percentTop="50px"
                  nameLeft="-70px"
                  pipeAbove="middle"
                  reverseValve
                  valveData={{ state: locationEquipment7?.CV2?.ioValues?.[IOValueKeys.position]?.value, name: 'CV2A' }}
                  binary={false}
                />
                <ValveConnect
                  readOnly
                  minWidth="0px"
                  percent
                  percentTop="50px"
                  nameLeft="-70px"
                  pipeAbove="middle"
                  reverseValve
                  valveData={{ state: locationEquipment7?.CV1?.ioValues?.[IOValueKeys.position]?.value, name: 'CV1' }}
                  binary={false}
                />
              </div>
              <PipePlaceholder horizontalAbove color={theme.pipeColors.water}>
                <Statistic
                  label={locationEquipment7?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.ioValueType?.units}
                  border
                  statistic={locationEquipment7?.Other?.ioValues[IOValueKeys.dischargeWaterPressure]?.value}
                  alertText={locationEquipment7?.Other?.ioValues.high_discharge_pressure?.value ? 'High Discharge Pressure' : null}
                  alertTop="25px"
                  alertLeft="-95px"
                />
              </PipePlaceholder>
              <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <div css={css`height: 15px;`}>
                  <PipePlaceholder pipeBelow="left" />
                </div>
              </div>
              {/* <div css={css`height: 100%; display: flex; flex: 1; min-width: ${theme.componentMinWidth};`}>
                <ValveConnect
                  reverseValve
                  minWidth="0px"
                  nameLeft="-80px"
                  pipeAbove="middle"
                  pipeBelow="right"
                  valveData={locationEquipment7?.LPDV}
                  IOValueKeys={{
                    opened: 'close_open',
                    closed: 'close_open',
                  }}
                  readValues={[1, 0]}
                  valveStateKeys={{
                    0: 'Closed',
                    1: 'Open',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                />
                <ValveConnect
                  reverseValve
                  minWidth="0px"
                  nameLeft="20px"
                  capAfter
                  capLabel="To Well"
                  capLabelTop="10px"
                  capLabelLeft="-65px"
                  pipeAbove="left"
                  pipeBelow="middle"
                  valveData={locationEquipment7?.SPDV}
                  IOValueKeys={{
                    opened: 'open_closed',
                    closed: 'open_closed',
                  }}
                  readValues={[1, 0]}
                  valveStateKeys={{
                    0: 'Closed',
                    1: 'Open',
                  }}
                  redImageKey="Closed"
                  greenImageKey="Open"
                />
              </div> */}
            </PumpsRowCustom>
            <PumpsRowCustom height="135px">
              {/* <PipePlaceholder hidden minWidth="51px" /> */}
              <div css={css`flex: 1; position: relative; height: 100%; min-width: 115px; width: 175px;`}>
                {/* <div css={css`width: 69vw; height: 100%; position: absolute; display: flex; align-items: center; justify-content: flex-start;`}> */}
                <div css={css`width: calc(100vw - 10px - ${props.navbarClosed ? '16px' : '160px'}); height: 100%; position: absolute; display: flex; align-items: center; justify-content: flex-start;`}>
                  <div css={css`flex: 1; display: flex; min-width: 115px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; display: flex; min-width: 175px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`display: flex; min-width: 35px;`}>
                    <Pipe horizontal color={theme.water} />
                  </div>
                  <div css={css`flex: 1; min-width: 175px;`} />
                  <div css={css`flex: 1; min-width: 51px;`} />
                </div>
                <div css={css`width: 175px; height: 100%; position: absolute; display: flex; align-items: center; justify-content: flex-start;`}>
                  <div css={[css`min-width: 0px; width: 175px;`, styles.mobilePumpWrapper]}>
                    <Pump2
                      pipeAbove="none"
                      pipeBelow="none"
                      setIOValue={props.setIOValue}
                      pumpData={locationEquipment7?.CP1}
                      otherData={locationEquipment7?.Other}
                      controlPositionTop="30px"
                      controlPositionLeft="-85px"
                      stateColorKey={{
                        0: `${theme.secondary}`,
                        1: `${theme.onGreen}`,
                        2: `${theme.warning}`,
                        3: `${theme.alarm}`,
                      }}
                      IOValueKeys={{
                        start: 'start',
                        stop: 'stop',
                        started: 'run_feedback',
                        stopped: 'run_feedback',
                      }}
                      writeValues={[1, 0]}
                      alarms={[
                      // props.equipment?.P1?.ioValues[IOValueKeys.warning],
                      // props.equipment?.P1?.ioValues[IOValueKeys.fault],
                      // props.equipment?.P1?.ioValues[IOValueKeys.lowAmps],
                      // props.equipment?.P1?.ioValues[IOValueKeys.masterAlarm],
                      // props.equipment?.Other?.ioValues[IOValueKeys.lowSuctionPressure],
                      ]}
                      stats={[
                        {
                          stat: locationEquipment7?.CP1?.ioValues?.[IOValueKeys.hours]?.value,
                          label: locationEquipment7?.CP1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div css={styles.mobilePumpWrapper} />
              <Pump
                percent
                pipeAbove="right"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P11}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  dischargePressure: 'back_water_pressure',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment7?.P11?.ioValues?.low_amperage,
                  locationEquipment7?.P11?.ioValues?.fault,
                ]}
                statuses={[
                  {
                    id: locationEquipment7?.P11?.ioValues?.heater_enabled?.id,
                    status: locationEquipment7?.P11?.ioValues?.heater_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Heater Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                  {
                    id: locationEquipment7?.P11?.ioValues?.coolant_enabled?.id,
                    status: locationEquipment7?.P11?.ioValues?.coolant_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Coolant Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment7?.P11?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P11?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P11?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P11?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P12}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                // writeValues={[0, 1]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment7?.P12?.ioValues?.low_amperage,
                  locationEquipment7?.P12?.ioValues?.fault,
                ]}
                statuses={[
                  {
                    id: locationEquipment7?.P12?.ioValues?.heater_enabled?.id,
                    status: locationEquipment7?.P12?.ioValues?.heater_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Heater Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                  {
                    id: locationEquipment7?.P12?.ioValues?.coolant_enabled?.id,
                    status: locationEquipment7?.P12?.ioValues?.coolant_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Coolant Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment7?.P12?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P12?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P12?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P12?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P13}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                // writeValues={[0, 1]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment7?.P13?.ioValues?.low_amperage,
                  locationEquipment7?.P13?.ioValues?.fault,
                ]}
                statuses={[
                  {
                    id: locationEquipment7?.P13?.ioValues?.heater_enabled?.id,
                    status: locationEquipment7?.P13?.ioValues?.heater_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Heater Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                  {
                    id: locationEquipment7?.P13?.ioValues?.coolant_enabled?.id,
                    status: locationEquipment7?.P13?.ioValues?.coolant_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Coolant Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment7?.P13?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P13?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P13?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P13?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P14}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                // writeValues={[0, 1]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment7?.P14?.ioValues?.low_amperage,
                  locationEquipment7?.P14?.ioValues?.fault,
                ]}
                statuses={[
                  {
                    id: locationEquipment7?.P14?.ioValues?.heater_enabled?.id,
                    status: locationEquipment7?.P14?.ioValues?.heater_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Heater Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                  {
                    id: locationEquipment7?.P14?.ioValues?.coolant_enabled?.id,
                    status: locationEquipment7?.P14?.ioValues?.coolant_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Coolant Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment7?.P14?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P14?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P14?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P14?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                percent
                pipeAbove="middle"
                pipeSizeAbove="large"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment7?.P15}
                otherData={locationEquipment7?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                IOValueKeys={{
                  start: 'start',
                  stop: 'stop',
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                }}
                // writeValues={[0, 1]}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                alarms={[
                  locationEquipment7?.P15?.ioValues?.low_amperage,
                  locationEquipment7?.P15?.ioValues?.fault,
                ]}
                statuses={[
                  {
                    id: locationEquipment7?.P15?.ioValues?.heater_enabled?.id,
                    status: locationEquipment7?.P15?.ioValues?.heater_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Heater Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                  {
                    id: locationEquipment7?.P15?.ioValues?.coolant_enabled?.id,
                    status: locationEquipment7?.P15?.ioValues?.coolant_enabled?.value,
                    statusKey: {
                      0: null,
                      1: 'Coolant Enabled',
                    },
                    statusColorKey: {
                      0: null, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment7?.P15?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment7?.P15?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment7?.P15?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment7?.P15?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <ValveConnect
                reverseValve
                // alignRight
                percent
                minWidth="51px"
                percentTop="50px"
                nameLeft="25px"
                pipeAbove="left"
                pipeSizeAbove="large"
                valveData={{
                  name: 'CV4',
                  id: locationEquipment7?.CV4?.id,
                  state: locationEquipment7?.CV4?.ioValues?.[IOValueKeys.position]?.value,
                }}
                binary={false}
              />
            </PumpsRowCustom>
            <PumpsRowCustom height="55px">
              <Pond
                flex="25"
                capBefore
                capBeforeLabel="From Transfer System"
                capBeforeLabelTop="-2px"
                capBeforeLabelLeft="65px"
                capBeforeLabelColor="white"
                capAfter
                capAfterLabel="From Connector Pond"
                capAfterLabelTop="-2px"
                capAfterLabelLeft="-215px"
                capAfterLabelColor="white"
                color={theme.pipeColors.water}
              >
                <Info
                  title="Well Level"
                  statistic={locationEquipment7?.Other?.ioValues?.well_level?.value}
                  precision={2}
                  label={locationEquipment7?.Other?.ioValues?.well_level?.ioValueType?.units}
                  // alertText={(() => {
                  //   if (locationEquipment7?.Other?.ioValues?.low_well_level?.value) {
                  //     return 'Low Well Level';
                  //   } else if (locationEquipment7?.Other?.ioValues?.low_well_level_warning?.value) {
                  //     return 'Low Well Level Warning';
                  //   } else {
                  //     return null;
                  //   }
                  // })()}
                  // alertTop="50px"
                  // alertLeft={locationEquipment7?.Other?.ioValues?.low_well_level_warning?.value ? '-50px' : '-15px'}
                />
              </Pond>
            </PumpsRowCustom>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexCol: css`
      display: flex;
      flex-direction: column;
      height: 100%;
      // min-width: 45px;
      flex: 1;
    `,
    mobilePumpWrapper: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        margin-top: 60px;
      }
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

NewRiverScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
};

NewRiverScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(NewRiverScreen);
